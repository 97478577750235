import type { Thunk } from '@/bootstrap/thunks.ts';
import {
  type BasketUnderlying,
  isElsBasketProduct,
} from '@/neos/business/rfq/strategy/leg/product/productModel.ts';

export function createUpdateElsBasketThunk<K extends keyof BasketUnderlying>(
  productId: string,
  key: K,
  value: BasketUnderlying[K],
): Thunk {
  return function updateElsBasketThunk(dispatch, getState, { actionCreators, selectors }) {
    const product = selectors.getProduct(getState(), productId);

    if (!isElsBasketProduct(product)) {
      return;
    }

    const newValue = typeof value === 'string' ? value || undefined : value;

    dispatch(
      actionCreators.neos.productCrudActions.update(product.uuid, {
        basketUnderlying: {
          ...product.basketUnderlying,
          [key]: newValue,
        },
      }),
    );
  };
}
