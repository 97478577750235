import { shallowEqual, type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { ExtendedDispatch } from '@/bootstrap/thunks.ts';
import type { AppState } from '@/bootstrap/store.ts';

// Use throughout your app instead of plain `useSelector`
export const useAppSelector: TypedUseSelectorHook<AppState> = (
  selector,
  equilityFnOrOption = { equalityFn: shallowEqual, devModeChecks: { stabilityCheck: 'never' } },
) => useSelector(selector, equilityFnOrOption);

export const useAppDispatch = useDispatch.withTypes<ExtendedDispatch>();
