import type { DisplayTimeZone } from '@/neos/business/ui/userPreferences/userPreferencesUiModel';
import {
  type BlotterColDef,
  getGridColumnDefinitionWithHeaderNames,
  GridColumnDefinition,
} from '@/neos/components/share/blotterGrid';

export const getBlotterColDefs = (
  displayTimezone: DisplayTimeZone,
  isTrader: boolean,
): BlotterColDef[] => {
  const { columns: c } = new GridColumnDefinition({ isTrader, displayTimezone });
  const preconfStatus = [c.preconfStatus()];
  const columns: BlotterColDef[] = [
    c.source(),
    c.tradeDate(),
    c.salesValo(),
    c.salesCounterpartyName(),
    c.statusLabel(),
    ...preconfStatus,
    c.masterQuantity(),
    c.masterSize(),
    c.masterLotSize(),
    c.legSizes(),
    c.underlyingBloombergCodes(),
    c.allStrategyTypes(),
    c.negotiationTypes(),
    c.elsType(),
    c.observableType(),
    c.activity(),
    c.secondaryEventType(),
    c.optionStyle(),
    c.legMaturities(),
    c.legStrikes(),
    c.traderGroups(),
    c.traders(),
    c.rfqBid(),
    c.rfqMid(),
    c.rfqAsk(),
    c.totalSalesMarginEur(),
    c.totalCommissionAmountEur(),
    c.crossBorder(),
    c.masterRefLevel(),
    c.eurNotionalValue(),
    c.usdNotionalValue(),
    c.clientWay(),
    c.extraFeatures(),
    c.comment(),
    c.quoteRecap(),
    c.workflow(),
    c.creationTime(),
    c.updateTime(),
    c.isQuotePercent(),
    c.uuid(),
    c.feedbackLevel(),
  ];

  return getGridColumnDefinitionWithHeaderNames(columns);
};
