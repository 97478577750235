import type { Thunk } from '@/bootstrap/thunks';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions';

export function createDefaultOnChangeBasketCurrencyThunk(rfqId: string): Thunk {
  return function defaultOnChangeBasketCurrencyThunk(
    dispatch,
    _,
    {
      actionCreators: {
        neos: { createDefaultRfqRequestedAction },
      },
    },
  ) {
    const defaultingOptions = new DefaultingOptions({
      overrideScopes: [
        DefaultingScope.SWAP_CURRENCY,
        DefaultingScope.CALCULATION_METHOD,
        DefaultingScope.BREAK_FEE,
        DefaultingScope.DIVIDEND_CURRENCY,
        DefaultingScope.INTEREST_RATE_INDEX,
        DefaultingScope.ROUNDING_STRATEGY,
        DefaultingScope.SETTLEMENT_LISTED,
        DefaultingScope.NOTIONAL,
        DefaultingScope.NEGOTIATED_SIZE,
        DefaultingScope.UNITS,
        DefaultingScope.BROKER_COMMISSION_CURRENCY,
      ],
      enrichScopes: [DefaultingScope.FOREX, DefaultingScope.SPOT_NET],
      isSettlementOtcOverride: true,
    });

    dispatch(createDefaultRfqRequestedAction(rfqId, defaultingOptions));
  };
}
