import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import {
  getVolStrikeValue,
  mapVolStrikeToQuoteByClientWay,
} from '@/neos/components/rfq/strategies/strategy/compositionLegs/quoteByClientWayHelpers.ts';
import { NumericInput } from '@/neos/components/share/numericInput';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';

interface VolStrikeProps {
  rfqId: string;
}

export function VolStrike({ rfqId }: VolStrikeProps) {
  const dispatch = useAppDispatch();
  const { clientWay } = useAppSelector(state => selectors.getRfqData(state, rfqId));
  const masterLeg = useAppSelector(state =>
    selectors.getStrategyMasterLegByRfqId(state, rfqId, selectors),
  );
  const masterQuote = useAppSelector(state => selectors.getQuote(state, masterLeg.quoteId));
  const volStrikeValue = getVolStrikeValue(clientWay, masterQuote);

  const onInputChange = (newValue: number | undefined) => {
    dispatch(
      neosActionCreators.quoteCrudActions.update(
        masterQuote.parentId.id,
        mapVolStrikeToQuoteByClientWay(clientWay, newValue),
      ),
    );
  };

  return (
    <section>
      <NumericInput
        inputClassName="fw-bold"
        unit="VOL"
        value={volStrikeValue}
        onBlur={onInputChange}
        readOnly={false}
        data-e2e="strategy-vol-strike"
      />
    </section>
  );
}
