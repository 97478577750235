import { neosThunks } from '@/neos/business/thunks';
import type { ImportedCompositionLegsData } from '@/neos/business/rfq/thunks/rfqSetUpImportedCompositionLegsThunk.ts';
import { ImportButton } from '@/neos/components/share/importExportClipboard/ImportButton.tsx';
import { useAppDispatch } from '@/bootstrap/hooks.ts';

type CompositionLegsImportProps = {
  rfqId: string;
  strategyId: string;
};

export const CompositionLegsImport = ({ rfqId, strategyId }: CompositionLegsImportProps) => {
  const dispatch = useAppDispatch();

  function onImport(importedData: unknown[]) {
    dispatch(
      neosThunks.createRfqSetUpImportedCompositionLegsThunk(
        rfqId,
        strategyId,
        importedData as ImportedCompositionLegsData[],
        'file',
      ),
    );
  }

  return <ImportButton handleImport={onImport} dataE2e="composition-legs-import" />;
};
