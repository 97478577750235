import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import {
  DATE_SHORT_KEBAB_FORMAT,
  DATE_SHORT_TIME_FORMAT,
  dateStringToIsoString,
  formatDateInTimeZone,
} from '@/util/date/dateFormatHelper.ts';
import { FlowDatePicker } from '@/neos/components/share/datePicker';
import { rfqDataActionCreators } from '@/neos/business/rfq/rfqData/rfqActionCreators.ts';
import { NeosTimeInput } from '@/neos/components/share/NeosTimePicker/NeosTimeInput.tsx';

export interface TradeDateProps {
  rfqId: string;
}

export function TradeDate({ rfqId }: TradeDateProps) {
  const dispatch = useAppDispatch();
  const displayTimezone = useAppSelector(state =>
    selectors.getDisplayTimeZone(state.ui.userPreferences),
  );
  const isTradeDateEditable = useAppSelector(
    state =>
      selectors.isStatusAtOrAfterOrderAccepted(state, rfqId, selectors) &&
      !selectors.isRfqStatusInAmendWorkflow(state, rfqId, selectors) &&
      selectors.isRfqEls(state, rfqId, selectors),
  );

  const { tradeDate } = useAppSelector(state => selectors.getRfqData(state, rfqId));

  const tradingDate = tradeDate
    ? {
        date: formatDateInTimeZone(
          displayTimezone,
          dateStringToIsoString(tradeDate),
          DATE_SHORT_KEBAB_FORMAT,
        ),
        time: formatDateInTimeZone(
          displayTimezone,
          dateStringToIsoString(tradeDate),
          DATE_SHORT_TIME_FORMAT,
        ),
      }
    : undefined;

  function updateTradeDate(newUpdatedDate: Date | undefined) {
    dispatch(
      rfqDataActionCreators.rfqDataCrudActions.update(rfqId, {
        tradeDate: newUpdatedDate?.toISOString(),
      }),
    );
  }

  const onDateChanged = (newDate: string) => {
    const today = new Date();

    const newUpdatedDate = new Date(newDate ?? tradingDate?.date ?? today);
    const [hours, minutes] = tradingDate?.time ? tradingDate.time.split(':') : [0, 0];

    if (displayTimezone === 'LOCAL') {
      newUpdatedDate.setHours(Number(hours), Number(minutes), 0, 0);
    } else {
      newUpdatedDate.setUTCHours(Number(hours), Number(minutes), 0, 0);
    }
    updateTradeDate(newUpdatedDate);
  };

  const onTimeChanged = (newTime: string | undefined) => {
    if (newTime === '' || newTime === undefined) {
      updateTradeDate(undefined);
      return;
    }

    const today = new Date();

    const newUpdatedDate = new Date(tradingDate?.date ?? today);
    const [hours, minutes] = newTime.split(':');

    if (displayTimezone === 'LOCAL') {
      newUpdatedDate.setHours(Number(hours), Number(minutes), 0, 0);
    } else {
      newUpdatedDate.setUTCHours(Number(hours), Number(minutes), 0, 0);
    }
    updateTradeDate(newUpdatedDate);
  };

  return (
    <div className="d-flex flex-nowrap ">
      <div
        className="d-grid align-items-center"
        style={{ minWidth: '270px', gridTemplateColumns: '50px 1fr 100px' }}
      >
        <label className="fw-bold me-2">Trade Date</label>
        <FlowDatePicker
          maturities={[]}
          date={tradingDate?.date ?? ''}
          onChange={onDateChanged}
          readOnly={!isTradeDateEditable}
          data-e2e="rfq-trade-date"
        />
        <NeosTimeInput
          time={tradingDate?.time}
          onBlur={onTimeChanged}
          data-e2e="rfq-trade-date-time"
          readOnly={!isTradeDateEditable}
        />
      </div>
    </div>
  );
}
