import type { Selectors } from '@/bootstrap/selectors';
import type { BlotterModelGetters } from '@/neos/components/share/blotterGrid';
import type { BlotterGridModel } from './BlotterGrid';
import type { AppState } from '@/bootstrap/store.ts';

export function getBlotterModel(
  state: AppState,
  selectors: Selectors,
  blotterModelGetters: BlotterModelGetters,
): BlotterGridModel {
  const pendingRfqIds = selectors.getPendingRfqIds(state.ui.blotter);
  const isVisible = selectors.isBlotterVisible(state.ui, selectors);
  const displayTimezone = selectors.getDisplayTimeZone(state.ui.userPreferences);

  const lastRequestTime = selectors.getBlotterLastRequestTime(state.blotter);
  const isTrader = selectors.isTrader(state);
  return {
    rows: blotterModelGetters.getBlotterRows(state, selectors, blotterModelGetters),
    pendingRfqIds,
    isVisible,
    displayTimezone,
    lastRequestTime,
    isTrader,
  };
}
