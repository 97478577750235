import { findIndex, size, sortedUniq } from 'lodash';
import type { FC } from 'react';
import { ErrorHighlight } from '../../share/errorHighlight';
import { NumericInput } from '../../share/numericInput';
import type { NotionalModel } from './getNotionalModel';

import { FormSelect } from 'react-bootstrap';
import { If } from '../../share/if/if';
import { NeosTooltip } from '../../share/tooltip/NeosTooltip';
import styles from './Notional.module.scss';

export interface NotionalOwnProps {
  rfqId: string;
}

export interface NotionalMapStateToProps {
  model: NotionalModel;
}

export interface NotionalDispatchProps {
  onNotionalChanged(notionalAmount: number | undefined): void;

  onPivotNotionalChanged(
    notionalAmount: number | undefined,
    selectedPivotNotionalCurrency: string | undefined,
  ): void;

  onNotionalCurrencyChanged(notionalCurrency: string | undefined): void;

  getSelectedPivotNotionalCurrency(selectedPivotNotionalCurrency: string | undefined): void;
}

export type NotionalProps = NotionalOwnProps & NotionalMapStateToProps & NotionalDispatchProps;

export const NotionalComponent: FC<NotionalProps> = props => {
  const notionalUnits = getAvailableNotionalUnits(props.model.notionalCurrency);

  const handlePivotNotionalUnitSwitch = (): void => {
    const {
      model: { currencyOptions, extraNotionalCurrency },
    } = props;
    const nextCurrency = getNextCurrency(currencyOptions, extraNotionalCurrency);
    props.getSelectedPivotNotionalCurrency(nextCurrency || extraNotionalCurrency);
  };

  return (
    <section className={`${styles['notional']}`}>
      <label>Notional:</label>
      {props.model.notinalCurrencyEditable ? (
        <FormSelect
          value={props.model.notionalCurrency}
          onChange={event => props.onNotionalCurrencyChanged(event.target.value)}
          readOnly={props.model.notionalDisabled}
        >
          <option value="">---</option>
          {notionalUnits.map(unit => (
            <option key={unit} value={unit}>
              {unit}
            </option>
          ))}
        </FormSelect>
      ) : (
        <div />
      )}
      <NeosTooltip
        id={'rfq-notional-' + props.rfqId}
        isDisplayed={!!props.model.notionalWarning}
        renderMessage={() =>
          props.model.notionalWarning ? <p>{props.model.notionalWarning}</p> : <></>
        }
        placement="auto"
        tooltipClassName="react-bootstrap-warning-tooltip"
        tooltipDataE2e="notional-warning-tooltip"
      >
        <ErrorHighlight errorField={'rfqNotional'} related={{ rfqId: props.rfqId }}>
          <NumericInput
            className="errorable-bloc"
            inputClassName={props.model.notionalWarning ? 'warning-bloc field-warning' : undefined}
            unit={props.model.notinalCurrencyEditable ? undefined : props.model.notionalCurrency}
            value={props.model.notionalAmount}
            onBlur={value => props.onNotionalChanged(value)}
            readOnly={props.model.notionalDisabled || !props.model.notionalCurrency}
            data-e2e="neos-rfq-notional-pivot-currency"
          />
        </ErrorHighlight>
      </NeosTooltip>
      <NumericInput
        unit={props.model.extraNotionalCurrency}
        value={props.model.extraNotionalAmount}
        onBlur={value => props.onPivotNotionalChanged(value, props.model.extraNotionalCurrency)}
        readOnly={props.model.extraDisabled}
        data-e2e="neos-rfq-notional-master-currency"
        {...(props.model.isExtraNotionalCurrencySwitchable
          ? { onUnitClick: handlePivotNotionalUnitSwitch }
          : {})}
      />

      <If condition={props.model.notionalInSwapCurrencyIsDisplayed ?? false}>
        <div style={{ gridColumn: '4/5' }}>
          <NumericInput
            unit={props.model.notionalInSwapCurrencyCurrency}
            value={props.model.notionalInSwapCurrencyAmount}
            readOnly
            data-e2e="neos-rfq-notional-in-swap-currency"
          />
        </div>
      </If>
    </section>
  );
};

export const getNextCurrency = (currencies: string[], current: string | undefined): string => {
  const index = findIndex(currencies, c => current === c);
  return currencies[(index + 1) % size(currencies)];
};

export const getAvailableNotionalUnits = (currency: string | undefined): string[] =>
  sortedUniq(
    [...['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'HKD', 'JPY', 'USD'], currency || '']
      .filter(c => !!c)
      .sort(),
  );
