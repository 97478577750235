import { camelCase } from 'lodash';

export const sanitizeColumnName = (column: string) => camelCase(column).toLowerCase();

export const sanitizeArrayOfColumnNames = (columns: string[]) =>
  columns.map(column => sanitizeColumnName(column));
// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()

export const bloombergCodeColumnAliases = [
  'underlying',
  'underlyingName',
  'underlying name',
  'name',
  'bloombergCode',
  'bbg',
  'bbg name',
  'bbgName',
  'bbg code',
  'bbgCode',
  'bloom',
];

export const quantityColumnsAliases = ['quantity', 'quantité', 'qty'];

export const typeAliases = ['type'];
