import { camelCase } from 'lodash';
import {
  compositionLegsBloombergCodeColumnAliases,
  compositionLegsExpectedNColumnAliases,
  compositionLegsSizeColumnAliases,
  compositionLegsVolStrikeColumnAliases,
  compositionLegsWeightColumnAliases,
} from '@/neos/components/share/importExportClipboard/utils/CompositionLegsColumnsImportAliases.ts';
import {
  sanitizeArrayOfColumnNames,
  sanitizeColumnName,
  typeAliases,
} from '@/neos/components/share/importExportClipboard/utils/CommonAliases.ts';
import type { RawImportedDataSchemaType } from '@/util/excel/excel.ts';

const importedCompositionLegsColumns = [
  'underlying',
  'type',
  'size',
  'weight',
  'expectedN',
  'volStrike',
] as const;

export type ImportedCompositionLegsColumns = (typeof importedCompositionLegsColumns)[number];

export type ImportedCompositionLegsData = {
  [K in ImportedCompositionLegsColumns]: string | undefined;
};

export function compositionLegsApproximatedImportedKeys(
  rawParsingData: RawImportedDataSchemaType,
): ImportedCompositionLegsData[] {
  const importedDataWithCamelCaseKeys: Record<string, string>[] = rawParsingData.map(line =>
    Object.fromEntries(
      Object.entries(line).map(([key, value]) => {
        return [camelCase(key), value];
      }),
    ),
  );

  const sanitizedKeys: ImportedCompositionLegsData[] = [];

  importedDataWithCamelCaseKeys.map(line => {
    const objectParsedFromLine: ImportedCompositionLegsData = {
      underlying: undefined,
      type: undefined,
      size: undefined,
      weight: undefined,
      expectedN: undefined,
      volStrike: undefined,
    };
    Object.entries(line).forEach(([key, value]) => {
      const column = sanitizeColumnName(key);
      for (const importedDataColumnName of importedCompositionLegsColumns) {
        if (sanitizeArrayOfColumnNames(columnMapping[importedDataColumnName]).includes(column)) {
          objectParsedFromLine[importedDataColumnName] = value;
          return;
        }
      }
    });
    sanitizedKeys.push(objectParsedFromLine);
  });
  return sanitizedKeys;
}

// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()
const columnMapping: Record<ImportedCompositionLegsColumns, string[]> = {
  underlying: compositionLegsBloombergCodeColumnAliases,
  type: typeAliases,
  size: compositionLegsSizeColumnAliases,
  weight: compositionLegsWeightColumnAliases,
  expectedN: compositionLegsExpectedNColumnAliases,
  volStrike: compositionLegsVolStrikeColumnAliases,
};
