import { selectors } from '@/bootstrap/selectors';
import type { ErrorableAllUi } from '@/neos/business/mappers/error/errorHandlerData';
import {
  type ErrorHighlightProps,
  ErrorHighlightWithTooltipComponent,
  type TooltipProps,
} from './ErrorHighlightWithTooltip';
import { getErrorHighlightWithTooltipModel } from './getErrorHighlightWithTooltipModel';
import { useAppSelector } from '@/bootstrap/hooks.ts';

export type ErrorHighlightWithTooltipOwnProps<T extends ErrorableAllUi> = Omit<
  TooltipProps,
  'discriminator'
> &
  Omit<ErrorHighlightProps<T>, 'discriminator'>;
export function ErrorHighlightWithTooltip<T extends ErrorableAllUi>(
  ownProps: ErrorHighlightWithTooltipOwnProps<T>,
) {
  const props = useAppSelector(state =>
    getErrorHighlightWithTooltipModel(state, ownProps, selectors),
  );
  return <ErrorHighlightWithTooltipComponent<T> {...props} />;
}
