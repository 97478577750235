import type { Thunk } from '@/bootstrap/thunks';
import { flatMap } from 'lodash';
import { mapVolStrikeToQuoteByClientWay } from '@/neos/components/rfq/strategies/strategy/compositionLegs/quoteByClientWayHelpers.ts';
import type { ImportedCompositionLegsData } from '@/neos/business/rfq/thunks/rfqSetUpImportedCompositionLegsThunk.ts';

export function createRfqApplyImportedCompositionLegsThunk(
  rfqId: string,
  strategyId: string,
  importedData: ImportedCompositionLegsData[],
): Thunk {
  return function rfqApplyImportedCompositionLegs(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: {
          legDataCrudActions,
          productCrudActions,
          quoteCrudActions,
          strategyUiCrudActions,
          createDefaultCompositionLegsRequestedAction,
        },
      },
    },
  ) {
    const state = getState();
    const { clientWay } = selectors.getRfqData(state, rfqId);
    const { legIds } = selectors.getStrategyData(state, strategyId);
    const legsData = legIds.map(legId => selectors.getLegData(state, legId));

    const productAndlegActions = flatMap(legsData, ({ uuid, quoteId, productId }, index) => {
      const leg = importedData[index];
      const underlyingUnit = selectors.getUnderlyingUnit(state, productId, selectors);

      return [
        legDataCrudActions.update(uuid, {
          weight: leg.weight ? leg.weight / 100 : undefined,
          initialWeight: leg.weight,
          notional: leg.size,
          notionalUnit: underlyingUnit,
        }),
        productCrudActions.update(productId, {
          expectedN: leg.expectedN,
        }),
        quoteCrudActions.update(quoteId, {
          ...mapVolStrikeToQuoteByClientWay(clientWay, leg.volStrike),
          unit: 'VOL',
        }),
      ];
    });

    const strategyUiActions = [
      strategyUiCrudActions.update(strategyId, {
        displayMissingCompositionDataWarning: importedDataHasMissingInfo(importedData),
      }),
    ];

    dispatch(...productAndlegActions, ...strategyUiActions);
    dispatch(createDefaultCompositionLegsRequestedAction(rfqId, strategyId));
  };
}

function importedDataHasMissingInfo(data: ImportedCompositionLegsData[]): boolean {
  return data.some(({ underlying, weight }) => !underlying || !weight);
}
