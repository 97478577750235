import type { Thunk } from '@/bootstrap/thunks.ts';
import { isElsProduct } from '@/neos/business/rfq/strategy/leg/product/productModel.ts';
import { type ImportMode, RawImportedDataSchema } from '@/util/excel/excel.ts';
import { elsBuyAndSellImportApproximateImportedKeys } from '@/neos/business/rfq/strategy/leg/product/els/ElsBuyAndSellImportApproximateImportedKeys.ts';
import { z } from 'zod';
import type { WayValues } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import { clientWays } from '@/neos/business/rfq/rfqData/rfqDataModel.ts';
import {
  internalEquityHedgeTypes,
  otherEquityHedgeTypes,
} from '@/neos/business/rfq/strategy/leg/product/elsProductOnyxModel.ts';
import { formatZodError, getZodStringNumber, unionOfLiterals } from '@/util/zod/zod-util.ts';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

export type ValidImportedEquityHedgeCompositionData = z.output<
  ReturnType<typeof getImportedBuyAndSellDataSchema>
>;

export function createImportElsBuyAndSellCompositionThunk(
  rfqId: string,
  productId: string,
  rawImportedData: unknown[],
  importedFrom: ImportMode,
): Thunk {
  return function importElsBuyAndSellCompositionThunk(
    dispatch,
    getState,
    { actionCreators, thunks, selectors },
  ) {
    const state = getState();
    const excelNumberFormat = selectors.selectExcelNumberFormat(state.ui.userPreferences);
    const product = selectors.getProduct(state, productId);
    const isRfqInternal = selectors.isRfqInternalEls(state, rfqId, selectors);

    if (!isElsProduct(product)) {
      return;
    }
    const rawParsingResult = RawImportedDataSchema.safeParse(rawImportedData);

    if (!rawParsingResult.success) {
      const zodError = formatZodError(
        rawParsingResult.error,
        'Error trying to parse imported lines',
      );

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const approximatedImportedData = elsBuyAndSellImportApproximateImportedKeys(
      rawParsingResult.data,
    );

    const parsingResult = getImportedBuyAndSellDataSchema(
      excelNumberFormat,
      importedFrom,
      isRfqInternal,
    ).safeParse(approximatedImportedData);

    if (!parsingResult.success) {
      const zodError = formatZodError(
        parsingResult.error,
        'Error trying to parse imported Buy And Sell composition',
      );

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const validImportedData = parsingResult.data as ValidImportedEquityHedgeCompositionData;

    dispatch(
      actionCreators.neos.createEquityHedgeCompositionImportUnderlyingIdsRequestedAction(
        rfqId,
        productId,
        validImportedData,
      ),
    );
  };
}

function getImportedBuyAndSellDataSchema(
  excelNumberFormat: ExcelNumberFormat,
  importedFrom: ImportMode,
  isRfqInternal: boolean,
) {
  const zodStringNumber = getZodStringNumber(excelNumberFormat, importedFrom);
  return z.array(
    z.object({
      bloombergCode: z.string().min(1),
      quantity: zodStringNumber(z.number().min(1)),
      spot: zodStringNumber().optional(),
      spotUnit: z.string().optional(),
      spotNet: zodStringNumber().optional(),
      spotNetUnit: z.string().optional(),
      execFeesIn: zodStringNumber().optional(),
      counterpart: z.string().optional(),
      broker: z.string().optional(),
      portfolio: z.string().optional(),
      ...(isRfqInternal && { internalPortfolio: z.string().optional() }),
      type: equityTypeSchema.optional(),
      way: clientWaySchema.optional(),
    }),
  );
}

const clientWaysValues = Object.values(clientWays) as ReadonlyArray<WayValues>;
export const clientWaySchema = unionOfLiterals(clientWaysValues);

export const equityTypeSchema = z.union([
  z.literal(internalEquityHedgeTypes.INTERNAL),
  z.literal(otherEquityHedgeTypes.BACK_TO_BACK),
  z.literal(otherEquityHedgeTypes.WITH_BROKER),
]);
