import type { Thunk } from '@/bootstrap/thunks';
import { uniq } from 'lodash';
import type { ChainOptions } from '../neosActions';

export function createRequestRfqMissingUnderlyingsThunk(
  rfqId: string,
  chainOptions?: ChainOptions,
  otherUnderlyingIdsToRetrieve: string[] = [],
): Thunk {
  return function requestMissingRfqUnderlyingsThunk(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingInfoRequestedAction, createPartialUnderlyingInfoRequestedAction },
      },
    },
  ) {
    const state = getState();

    const doesRfqWorksWithPartialUndlInfo =
      selectors.isRfqElsBasket(state, rfqId, selectors) ||
      selectors.isRfqVarVolDisp(state, rfqId, selectors);

    const underlyingsIdsWithoutAnyInfo = selectors.getUnderlyingsIdsWithoutAnyInfo(
      state,
      rfqId,
      selectors,
    );

    const underlyingsIdsWithoutFullInfo = selectors.getUnderlyingsIdsWithoutFullInfo(
      state,
      rfqId,
      selectors,
    );
    const underlyingsIdsWithMissingUdlInfo = doesRfqWorksWithPartialUndlInfo
      ? underlyingsIdsWithoutAnyInfo
      : underlyingsIdsWithoutFullInfo;

    const allMissingUnderlyingIds = underlyingsIdsWithMissingUdlInfo.concat(
      otherUnderlyingIdsToRetrieve,
    );

    const uniqMissingUnderlyingIds = uniq(allMissingUnderlyingIds);

    if (
      doesRfqWorksWithPartialUndlInfo &&
      (underlyingsIdsWithoutAnyInfo.length > 0 || otherUnderlyingIdsToRetrieve.length > 0)
    ) {
      dispatch(
        createPartialUnderlyingInfoRequestedAction([rfqId], uniqMissingUnderlyingIds, chainOptions),
      );
    } else if (
      !doesRfqWorksWithPartialUndlInfo &&
      (underlyingsIdsWithoutFullInfo.length > 0 || otherUnderlyingIdsToRetrieve.length > 0)
    ) {
      dispatch(
        createUnderlyingInfoRequestedAction([rfqId], uniqMissingUnderlyingIds, chainOptions),
      );
    } else {
      const dispatchables = chainOptions?.success?.dispatchables ?? [];
      dispatchables.forEach(toBeDispatched => dispatch(toBeDispatched));
    }
  };
}
