import type { Thunk } from '@/bootstrap/thunks';
import type { BloombergCodeByProductId } from '@/neos/business/referenceData/underlying/underlyingActionCreators';
import { z } from 'zod';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';
import { type ImportMode, RawImportedDataSchema } from '@/util/excel/excel.ts';
import { formatZodError, getZodStringNumber } from '@/util/zod/zod-util.ts';
import { compositionLegsApproximatedImportedKeys } from '@/neos/business/rfq/strategy/compositionLegs/CompositionLegsApproximateImportedKeys.ts';

export interface ImportedCompositionLegsData {
  underlying?: string;
  type?: string;
  weight?: number;
  expectedN?: number;
  volStrike?: number;
  size?: number;
}

export type ValidImportedLegsCompositionData = z.output<
  ReturnType<typeof getImportedCompositionDataSchema>
>;

export function createRfqSetUpImportedCompositionLegsThunk(
  rfqId: string,
  strategyId: string,
  importedData: unknown[],
  importedFrom: ImportMode,
): Thunk {
  return function rfqSetUpImportedCompositionLegs(
    dispatch,
    getState,
    {
      selectors,
      actionCreators: {
        neos: { createUnderlyingIdsRequestedAction, strategyUiCrudActions },
        common: { createLogAction },
      },
      thunks: {
        neos: {
          createRfqApplyImportedCompositionLegsThunk,
          createRequestRfqMissingUnderlyingsThunk,
          createRfqAddLegsThunk,
          createRfqDeleteLegThunk,
        },
        createErrorToasterThunk,
      },
    },
  ) {
    const state = getState();
    const excelNumberFormat = selectors.selectExcelNumberFormat(state.ui.userPreferences);

    if (!selectors.isVarVolDispersionStrategy(state, strategyId, selectors)) {
      return;
    }

    const rawParsingResult = RawImportedDataSchema.safeParse(importedData);

    if (!rawParsingResult.success) {
      const zodError = formatZodError(
        rawParsingResult.error,
        'Error trying to parse imported composition',
      );

      dispatch(createLogAction(zodError, undefined, true));
      dispatch(
        createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const approximatedImportedData = compositionLegsApproximatedImportedKeys(rawParsingResult.data);

    const parsingResult = getImportedCompositionDataSchema(
      excelNumberFormat,
      importedFrom,
    ).safeParse(approximatedImportedData);

    if (!parsingResult.success) {
      const zodError = formatZodError(
        parsingResult.error,
        'Error trying to parse imported composition',
      );

      dispatch(createLogAction(zodError, undefined, true));
      dispatch(
        createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const validImportedData = parsingResult.data as ValidImportedLegsCompositionData;

    const indexData = validImportedData.filter(id => id.type?.toLocaleLowerCase() === 'i');
    if (indexData.length > 1) {
      dispatch(
        strategyUiCrudActions.update(strategyId, {
          displayCompoImportHasMultipleIndicesWarning: true,
        }),
      );
      return;
    }
    const compositionData = validImportedData.filter(id => id.type?.toLocaleLowerCase() === 'c');
    const initialLegs = selectors.getRfqLegsData(state, rfqId, selectors);

    initialLegs
      .filter(leg => !leg.isMaster)
      .map(leg => leg.uuid)
      .forEach(legToDeleteId => {
        dispatch(createRfqDeleteLegThunk(legToDeleteId));
      });

    dispatch(createRfqAddLegsThunk(strategyId, compositionData.length));

    const updatedState = getState();
    const { legIds } = selectors.getStrategyData(updatedState, strategyId);
    const legsData = legIds.map(legId => selectors.getLegData(updatedState, legId));

    const bloombergCodesByProductId: BloombergCodeByProductId[] = legsData
      .map(({ productId }, index) => ({
        productId,
        bloombergCode: validImportedData[index].underlying,
      }))
      .filter(
        (bloombergCodeByProductId): bloombergCodeByProductId is BloombergCodeByProductId =>
          bloombergCodeByProductId.bloombergCode !== undefined,
      );

    if (bloombergCodesByProductId.length) {
      dispatch(
        createUnderlyingIdsRequestedAction([rfqId], bloombergCodesByProductId, {
          success: {
            dispatchables: [
              createRequestRfqMissingUnderlyingsThunk(rfqId, {
                success: {
                  dispatchables: [
                    createRfqApplyImportedCompositionLegsThunk(
                      rfqId,
                      strategyId,
                      validImportedData,
                    ),
                  ],
                },
              }),
            ],
          },
        }),
      );
    }
  };
}

function getImportedCompositionDataSchema(
  excelNumberFormat: ExcelNumberFormat,
  importedFrom: ImportMode,
) {
  const zodStringNumber = getZodStringNumber(excelNumberFormat, importedFrom);

  return z.array(
    z.object({
      type: z.enum(['I', 'C']),
      underlying: z.string().min(1),
      size: zodStringNumber(z.number().min(1)),
      weight: zodStringNumber(z.number().min(1)),
      expectedN: zodStringNumber(z.number().min(1)),
      volStrike: zodStringNumber(z.number().min(1)),
    }),
  );
}
