import type { Status } from '../../../business/neosModel';
import type { BlotterGridProps } from './BlotterGrid';
import { getBlotterColDefs } from './blotterColumnDefinitions';

import { DATE_FILE_FORMAT, formatNow } from '@/util/date/dateFormatHelper';
import { type BlotterRowModel, updateAndReturnSelectedRows } from '../../share/blotterGrid';
import { getClassNameByStatusAndSubStatus } from '../../share/blotterGrid/statusColumnStyle';

import type { BulkCancelReason } from '@/neos/business/blotter/blotterModel';
import type {
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  ValueFormatterParams,
} from '@ag-grid-community/core';

export const MAX_BLOTTER_RFQS_TO_OPEN = 10;

export function getGridStaticOptions(props: BlotterGridProps): GridOptions {
  const columnDefs = getBlotterColDefs(props.displayTimezone, props.isTrader);
  return {
    columnDefs,
    defaultColDef: {
      enableRowGroup: true,
      enablePivot: true,
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    sideBar: false,
    suppressRowClickSelection: false,
    rowSelection: 'multiple',
    enableRangeSelection: true,
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'min', 'max', 'avg'],
          },
        },
      ],
    },
    animateRows: false,
    suppressAggFuncInHeader: false,
    getRowClass: props.isTrader ? rowClassIfTraderGetter : undefined,
    getRowId: (params: any) => params.data.uuid,
    getContextMenuItems: getContextMenuItems(props),
    tooltipShowDelay: 0.6,
    isRowSelectable: rowNode => (rowNode.data ? rowNode.data.isLoadableRfq : false),
    onRowDoubleClicked: e => {
      const data: BlotterRowModel | undefined = e.data;
      if (data) {
        return data.isLoadableRfq
          ? props.loadLatestTransactions([data.uuid])
          : props.displayNotManagedRfqError(data);
      }
    },
  };
}

const traderStatuses: Status[] = [
  'PRICE_REQUESTED',
  'BEING_PRICED',
  'BOOKING_REQUESTED',
  'POSITION_BEING_BOOKED',
  'PRE_TRADE_BEING_BOOKED',
  'PRE_TRADE_BOOKING_REQUESTED',
];
const rowClassIfTraderGetter = ({ data }: any) => {
  const { status, subStatus } = (data ?? {}) as Partial<
    Pick<BlotterRowModel, 'status' | 'subStatus'>
  >;
  if (status && traderStatuses.includes(status)) {
    return getClassNameByStatusAndSubStatus(status, subStatus, true) ?? '';
  }
  return [];
};

function getContextMenuItems(props: BlotterGridProps) {
  return (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const selectedRows = updateAndReturnSelectedRows(params);
    const selectedIds = selectedRows.filter(node => node.isLoadableRfq).map(node => node.uuid);

    const manualPricingHandler = () => {
      if (selectedIds.length > 0) {
        props.generateManualPricing(selectedIds);
      }
    };

    const loadMultipleRfq = () => {
      if (selectedIds.length > 0) {
        props.loadLatestTransactions(selectedIds);
      }
    };

    const quoteRecapHandler = () => {
      props.copyQuoteRecapsToClipboard(selectedIds);
    };

    const tradeRecapHandler = () => {
      props.copyTradeRecapsToClipboard(selectedIds);
    };

    const rfqIdCopyHandler = () => {
      props.copyRfqIdsToClipboard(selectedIds);
    };

    const bulkCancelHandler = (reason: BulkCancelReason) => {
      if (selectedIds.length > 0) {
        props.onBulkCancelRfqs(selectedIds, reason);
      }
    };

    const bulkCancelMenu: MenuItemDef[] = [
      {
        name: 'Quick Actions',
        subMenu: [
          { name: 'Abort', action: () => bulkCancelHandler('abort') },
          { name: 'Do Not Quote', action: () => bulkCancelHandler('doNotQuote') },
          { name: 'Client Do Nothing', action: () => bulkCancelHandler('clientDoNothing') },
          { name: 'Client Trade Away', action: () => bulkCancelHandler('clientTradeAway') },
        ],
        disabled: selectedIds.length === 0,
      },
    ];
    const plural = selectedIds.length !== 1 ? 's' : '';

    const areTooMuchRfqsSelected = selectedIds.length > MAX_BLOTTER_RFQS_TO_OPEN;

    return [
      {
        name: 'Open RFQ' + plural,
        tooltip: areTooMuchRfqsSelected
          ? `Too many RFQs selected at once. Please open no more than ${MAX_BLOTTER_RFQS_TO_OPEN}.`
          : undefined,
        action: loadMultipleRfq,
        disabled: selectedIds.length === 0 || areTooMuchRfqsSelected,
      },
      {
        name: 'Manual Pricing',
        action: manualPricingHandler,
        disabled: selectedIds.length === 0,
      },
      {
        name: 'Copy Quote Recap' + plural,
        action: quoteRecapHandler,
        disabled: !selectedRows.filter(({ quoteRecap }) => quoteRecap).length,
      },
      {
        name: 'Copy Trade Recap' + plural,
        action: tradeRecapHandler,
        disabled: !selectedRows.filter(({ tradeRecap }) => tradeRecap).length,
      },
      {
        name: 'Copy RFQ ID' + plural,
        action: rfqIdCopyHandler,
        disabled: selectedIds.length === 0,
      },
      ...bulkCancelMenu,
      'separator',
      'copy',
      'copyWithHeaders',
      'paste',
      {
        name: 'Excel Export',
        action: () => {
          const { api } = params;
          if (!api) {
            return;
          }
          api.exportDataAsExcel({
            fileName: 'NEOS Blotter ' + formatNow(DATE_FILE_FORMAT) + '.xls',
            sheetName: 'NEOS Blotter Export',
            processCellCallback: params => {
              const { valueFormatter } = params.column.getColDef();
              if (params.node && valueFormatter && valueFormatter instanceof Function) {
                const valueFormatterParams: ValueFormatterParams = {
                  ...params,
                  data: params.node.data,
                  node: params.node!,
                  colDef: params.column.getColDef(),
                };
                return valueFormatter(valueFormatterParams);
              }
              return params.value;
            },
          });
        },
      },
    ];
  };
}
