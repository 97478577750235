import type { Selectors } from '@/bootstrap/selectors';
import type { UnderlyingChangedParameters } from '@/neos/business/underlyingInfo/underlyingInfoActions';
import { isSingleUnderlyingDerivativeProduct } from '../neosModel';
import type { AppState } from '@/bootstrap/store.ts';

export const changeUnderlyingSelectors = {
  getUnderlyingChangeData,
  getSupposedLegIds,
};

export interface UnderlyingChangeData {
  affectedStrategyId: string;
  affectedLegIds: string[];
  affectedProductIds: string[];
  isStrategyUnderlyingAffected: boolean;
}

function getUnderlyingChangeData(
  state: AppState,
  rfqId: string,
  parameters: UnderlyingChangedParameters,
  selectors: Selectors,
): UnderlyingChangeData[] {
  const { underlyingId } = parameters;

  const { getProduct, getLegData, getStrategyIdsForShortcut, hasACompositionLeg } = selectors;

  const strategyIds =
    parameters.type === 'FROM_SHORTCUTS'
      ? getStrategyIdsForShortcut(state, rfqId, parameters.isApplyToAll, selectors)
      : [parameters.strategyId];

  return strategyIds
    .map((strategyId: string): UnderlyingChangeData => {
      const supposedLegIds = selectors.getSupposedLegIds(state, strategyId, parameters, selectors);

      const affectedLegIds = supposedLegIds.filter(legId => {
        const { productId } = getLegData(state, legId);
        const product = getProduct(state, productId);
        return (
          isSingleUnderlyingDerivativeProduct(product) && product.underlyingId !== underlyingId
        );
      });

      const affectedProductIds = affectedLegIds.map(lId => getLegData(state, lId).productId);
      const isStrategyUnderlyingAffected = getIsStrategyUnderlyingAffected();
      return {
        affectedStrategyId: strategyId,
        affectedLegIds,
        affectedProductIds,
        isStrategyUnderlyingAffected,
      };

      function getIsStrategyUnderlyingAffected() {
        if (
          parameters.type !== 'FROM_SHORTCUTS' ||
          !hasACompositionLeg(state, strategyId, selectors)
        ) {
          return false;
        }

        return !parameters.selectedLegId || parameters.isApplyToAll;
      }
    })
    .filter(
      ({ affectedProductIds, isStrategyUnderlyingAffected }) =>
        affectedProductIds.length !== 0 || isStrategyUnderlyingAffected,
    );
}

function getSupposedLegIds(
  state: AppState,
  strategyId: string,
  parameters: UnderlyingChangedParameters,
  selectors: Selectors,
) {
  const { strategyType, legIds } = selectors.getStrategyData(state, strategyId);
  const { sameProductUnderlying, hasACompositionLeg } = selectors.getStrategyDefinition(
    state.referenceData,
    strategyType,
  );
  if (sameProductUnderlying) {
    return legIds;
  }

  const selectedLegId =
    parameters.type === 'FROM_SHORTCUTS'
      ? legIds.find(lId => lId === parameters.selectedLegId)
      : undefined;
  return parameters.type === 'FROM_UNDERLYING_FIELD'
    ? [parameters.legId]
    : parameters.isApplyToAll || !selectedLegId
      ? getlegIdForNoSelectedLegOrApplyAll()
      : [selectedLegId];

  function getlegIdForNoSelectedLegOrApplyAll(): string[] {
    if (!hasACompositionLeg) {
      return [selectors.getStrategyMasterLeg(state, strategyId, selectors).uuid];
    }
    const indexLeg = selectors.getStrategyMasterLeg(state, strategyId, selectors);
    if (indexLeg === undefined) {
      return [];
    }

    return [indexLeg.uuid];
  }
}
