import type { Dispatchable, Thunk } from '@/bootstrap/thunks';
import { isElsBasketProduct, isElsProduct } from '../legModel';
import { DefaultingScope } from '@/neos/business/rfq/models';
import { DefaultingOptions } from '@/neos/business/rfq/rfqActions.ts';

export function createSwapCurrencyChangeThunk(
  rfqId: string,
  strategyId: string,
  productId: string,
  swapCurrency: string,
): Thunk {
  return function swapCurrencyChangeThunk(
    dispatch,
    getState,
    { selectors, actionCreators, thunks },
  ) {
    const state = getState();
    const isInitiatedByTrader = selectors.isRfqInitiatedByTrader(state, rfqId);
    const product = selectors.getStrategyMasterProduct(state, strategyId, selectors);

    const dispatchables: Array<Dispatchable> = [
      thunks.neos.createUpdateProductAndResetDependenciesThunk(
        rfqId,
        strategyId,
        productId,
        'swapCurrency',
        swapCurrency,
      ),
    ];

    if (!isElsProduct(product)) {
      dispatchables.push(
        thunks.neos.createFetchCurvesListBasedOnCurrencyThunk(rfqId, [strategyId]),
      );
    } else {
      dispatchables.push(
        actionCreators.neos.createCurvesListRequestedAction(swapCurrency, rfqId, 'ELS'),
      );

      const overrideScopes = [
        DefaultingScope.CALCULATION_METHOD,
        DefaultingScope.BREAK_FEE,
        DefaultingScope.DIVIDEND_CURRENCY,
        DefaultingScope.INTEREST_RATE_INDEX,
        DefaultingScope.UNITS,
      ];

      const enrichScopes = [DefaultingScope.FOREX];

      if (isInitiatedByTrader) {
        overrideScopes.push(DefaultingScope.BROKER_COMMISSION_CURRENCY);
      }

      if (isElsBasketProduct(product)) {
        product.basketUnderlying.basketComposition.map(basketCompo =>
          dispatchables.push(
            actionCreators.neos.referenceCrudActions.update(
              {
                rfqId,
                underlyingId: basketCompo.underlyingId ?? '',
              },
              { refSpotNetSwapCurrencyUnit: swapCurrency },
            ),
          ),
        );

        overrideScopes.push(
          DefaultingScope.UNDERLYING,
          DefaultingScope.NOTIONAL,
          DefaultingScope.NEGOTIATED_SIZE,
        );
        enrichScopes.push(DefaultingScope.SPOT_NET);
      }

      const options = new DefaultingOptions({
        overrideScopes,
        enrichScopes,
        isSettlementOtcOverride: true,
      });

      dispatchables.push(actionCreators.neos.createDefaultRfqRequestedAction(rfqId, options));
    }

    dispatch(dispatchables);
  };
}
