import type { Thunk } from '@/bootstrap/thunks';
import { isElsBasketProduct } from '@/neos/business/rfq/strategy/leg/product/productModel';
import type { ExecFees } from '@/neos/business/rfq/strategy/leg/product/productOnyxModel';
import { createZodObject, formatZodError, getZodStringNumber } from '@/util/zod/zod-util';
import { z } from 'zod';
import { type ImportMode, RawImportedDataSchema } from '@/util/excel/excel.ts';
import { elsBasketImportApproximateImportedKeys } from '@/neos/business/rfq/strategy/leg/product/els/ElsBasketImportApproximateImportedKeys.ts';
import type { ExcelNumberFormat } from '@/neos/business/ui/userPreferences/userPreferencesUiModel.ts';

export type ValidImportedBasketData = z.output<ReturnType<typeof getImportedBasketDataSchema>>;

export function createImportBasketCompositionThunk(
  rfqId: string,
  productId: string,
  rawImportedData: unknown[],
  importedFrom: ImportMode,
): Thunk {
  return function importBasketCompositionThunk(
    dispatch,
    getState,
    { actionCreators, thunks, selectors },
  ) {
    const state = getState();
    const excelNumberFormat = selectors.selectExcelNumberFormat(state.ui.userPreferences);
    const product = selectors.getProduct(state, productId);

    if (!isElsBasketProduct(product)) {
      return;
    }

    const rawParsingResult = RawImportedDataSchema.safeParse(rawImportedData);

    if (!rawParsingResult.success) {
      const zodError = formatZodError(
        rawParsingResult.error,
        'Error trying to parse imported basket composition',
      );

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const approximatedImportedData = elsBasketImportApproximateImportedKeys(rawParsingResult.data);

    const parsingResult = getImportedBasketDataSchema(excelNumberFormat, importedFrom).safeParse(
      approximatedImportedData,
    );

    if (!parsingResult.success) {
      const zodError = formatZodError(
        parsingResult.error,
        'Error trying to parse imported basket composition',
      );

      dispatch(actionCreators.common.createLogAction(zodError, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message: zodError,
          },
          undefined,
        ),
      );
      return;
    }

    const validatedData = parsingResult.data as ValidImportedBasketData;

    const importedBloombergCodes = validatedData.map(line => line.bloombergCode);

    const areSomeDuplicatedBloombergCodes = importedBloombergCodes.some((item, index) => {
      return importedBloombergCodes.indexOf(item) !== index;
    });

    if (areSomeDuplicatedBloombergCodes) {
      const message =
        'Error trying to import basket composition: Some Bloomberg codes are duplicated.';
      dispatch(actionCreators.common.createLogAction(message, undefined, true));
      dispatch(
        thunks.createErrorToasterThunk(
          {
            message,
          },
          undefined,
        ),
      );
      return;
    }

    const definedData = validatedData.filter(isBloombergCodeDefined);

    if (definedData.length > 0) {
      dispatch(
        actionCreators.neos.createBasketUnderlyingIdsRequestedAction(
          rfqId,
          product.uuid,
          definedData,
        ),
      );
    }
  };
}

const ExecFeesSchema = createZodObject<ExecFees>({
  value: z.number().optional(),
  unit: z.union([z.literal('bp'), z.literal('%'), z.literal('Cts')]).optional(),
  type: z.union([z.literal('BIPS'), z.literal('REF_PERCENT'), z.literal('CENTS')]).optional(),
});

function getImportedBasketDataSchema(
  excelNumberFormat: ExcelNumberFormat,
  importedFrom: ImportMode,
) {
  const zodStringNumber = getZodStringNumber(excelNumberFormat, importedFrom);

  return z.array(
    z.object({
      bloombergCode: z.string().min(1),
      quantity: zodStringNumber(z.number().min(1)),
      spot: zodStringNumber().optional(),
      spotUnit: z.string().optional(),
      spotNet: zodStringNumber().optional(),
      spotNetUnit: z.string().optional(),
      spotNetInSwapCcy: zodStringNumber().optional(),
      execFeesIn: zodStringNumber().optional(),
      execFeesInUnit: ExecFeesSchema.shape.unit.optional(),
      execFeesOut: zodStringNumber().optional(),
      execFeesOutUnit: ExecFeesSchema.shape.unit.optional(),
    }),
  );
}

function isBloombergCodeDefined(
  line: ValidImportedBasketData[number],
): line is ValidImportedBasketData[number] {
  return line.bloombergCode !== undefined;
}
