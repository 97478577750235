import {
  bloombergCodeColumnAliases,
  quantityColumnsAliases,
} from '@/neos/components/share/importExportClipboard/utils/CommonAliases.ts';

// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()

export const basketBloombergCodeColumnAliases = bloombergCodeColumnAliases.concat([
  'Ticker',
  'Tickers',
  'Basket Underlying',
]);

export const basketQuantityColumnAliases = quantityColumnsAliases.concat(['Basket Quantity']);

export const basketSpotColumnAliases = [
  'spot',
  'price',
  'openprice',
  'open price',
  'closeprice',
  'close price',
  'px',
  'prix',
  'Basket Spot Gross',
];

// ELS BASKET
export const spotNetSwapCcyAliases = ['Spot Net in Swap Ccy'];

// NOTE: BUY & SELL
export const spotUnitAliases = ['Spot Unit'];

export const spotNetAliases = ['Spot Net'];
export const spotNetUnitAliases = ['Spot Net Unit'];

export const counterpartAliases = ['counterpart'];

export const brokerAliases = ['broker'];

export const portfolioAliases = ['portfolio'];

export const internalPortfolioAliases = ['Internal Portfolio', 'Internal Ptf'];

export const wayAliases = ['way'];
