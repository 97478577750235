import {
  basketBloombergCodeColumnAliases,
  basketQuantityColumnAliases,
  basketSpotColumnAliases,
  spotNetSwapCcyAliases,
} from '@/neos/components/share/importExportClipboard/utils/ElsColumnsImportAliases.ts';
import { camelCase } from 'lodash';
import {
  sanitizeArrayOfColumnNames,
  sanitizeColumnName,
} from '@/neos/components/share/importExportClipboard/utils/CommonAliases.ts';

export function elsBasketImportApproximateImportedKeys(
  rawParsingData: Record<string, string>[],
): Record<string, string>[] {
  const importedDataWithCamelCaseKeys: Record<string, string>[] = rawParsingData.map(line =>
    Object.fromEntries(
      Object.entries(line).map(([key, value]) => {
        return [camelCase(key), value];
      }),
    ),
  );

  return importedDataWithCamelCaseKeys.map(line => {
    return Object.fromEntries(
      Object.entries(line).map(([key, value]) => {
        const column = sanitizeColumnName(key);
        if (
          sanitizeArrayOfColumnNames(BasketColumnNameConversions.BLOOMBERG_CODE).includes(column)
        ) {
          return ['bloombergCode', value];
        }
        if (sanitizeArrayOfColumnNames(BasketColumnNameConversions.QUANTITY).includes(column)) {
          return ['quantity', value];
        }
        if (sanitizeArrayOfColumnNames(BasketColumnNameConversions.SPOT).includes(column)) {
          return ['spot', value];
        }
        if (
          sanitizeArrayOfColumnNames(BasketColumnNameConversions.SPOT_NET_IN_SWAP_CCY).includes(
            column,
          )
        ) {
          return ['spotNetInSwapCcy', value];
        }

        return [key, value];
      }),
    );
  });
}

// These aliases are NOT exhaustive because they are evaluated case-insensitive and space-insensitive in sanitizeColumnName()
export const BasketColumnNameConversions = {
  BLOOMBERG_CODE: basketBloombergCodeColumnAliases,
  QUANTITY: basketQuantityColumnAliases,
  SPOT: basketSpotColumnAliases,
  SPOT_NET_IN_SWAP_CCY: spotNetSwapCcyAliases,
};
