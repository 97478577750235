import type { ScheduleSchemaOutput } from '@/util/excel/excel.ts';
import { sanitizeColumnName } from '@/neos/components/share/importExportClipboard/utils/CommonAliases.ts';

export interface LegPeriodDatesColumnNames {
  equityStartDate: string;
  equityEndDate: string;
  equityPayDate: string;
  rateFirstFixingDate: string;
  rateFixingDate: string;
  rateStartDate: string;
  rateEndDate: string;
  ratePayDate: string;
  dividendStartDate: string;
  dividendEndDate: string;
  dividendTheoreticalPaymentDate: string;
  dividendPayDate: string;
}

const columnCorrespondenceMap: Map<string, keyof LegPeriodDatesColumnNames> = new Map([
  [sanitizeColumnName('Equity Start Date'), 'equityStartDate'],
  [sanitizeColumnName('Equity End Date'), 'equityEndDate'],
  [sanitizeColumnName('Equity Pay Date'), 'equityPayDate'],
  [sanitizeColumnName('Rate First Fixing Date'), 'rateFirstFixingDate'],
  [sanitizeColumnName('Rate Last Fixing Date'), 'rateFixingDate'],
  [sanitizeColumnName('Rate Fixing Date'), 'rateFixingDate'],
  [sanitizeColumnName('Rate Start Date'), 'rateStartDate'],
  [sanitizeColumnName('Rate End Date'), 'rateEndDate'],
  [sanitizeColumnName('Rate Pay Date'), 'ratePayDate'],
  [sanitizeColumnName('Dividend Start Date'), 'dividendStartDate'],
  [sanitizeColumnName('Dividend End Date'), 'dividendEndDate'],
  [sanitizeColumnName('Dividend Theoretical Payment Date'), 'dividendTheoreticalPaymentDate'],
  [sanitizeColumnName('Dividend Pay Date'), 'dividendPayDate'],
]);

export function mapFromScheduleImportedData(
  rawImportedData: ScheduleSchemaOutput,
): Partial<LegPeriodDatesColumnNames>[] {
  const schedules: Partial<LegPeriodDatesColumnNames>[] = [];

  rawImportedData.forEach(line => {
    const scheduleToAdd: Partial<LegPeriodDatesColumnNames> = {};
    Object.entries(line).forEach(([originalColumnName, value]) => {
      if (value === null) {
        return;
      }
      const sanitizedColumnName = sanitizeColumnName(originalColumnName);
      const legPeriodField = columnCorrespondenceMap.get(sanitizedColumnName);
      if (legPeriodField !== undefined) {
        scheduleToAdd[legPeriodField] = value;
      }
    });
    schedules.push(scheduleToAdd);
  });

  return schedules;
}
