import { ErrorHighlight } from '@/neos/components/share/errorHighlight';
import { UnderlyingInput } from '@/neos/components/rfq/strategies/strategy/common';
import type { TypeaheadValue } from '@/neos/components/share/typeahead';
import { NumericInput } from '@/neos/components/share/numericInput';
import { neosThunks } from '@/neos/business/thunks';
import { neosActionCreators } from '@/neos/business/neosActionCreators.ts';
import { mapVolStrikeToQuoteByClientWay } from '@/neos/components/rfq/strategies/strategy/compositionLegs/quoteByClientWayHelpers.ts';
import { LegRecomputeChangedScope } from '@/neos/business/rfq/models';
import { useAppDispatch, useAppSelector } from '@/bootstrap/hooks.ts';
import { getCompositionLegsGridModel } from '@/neos/components/rfq/strategies/strategy/compositionLegs/getCompositionLegsGridModel.ts';
import { selectors } from '@/bootstrap/selectors.ts';
import type { CompositionLeg } from '@/neos/components/rfq/strategies/strategy/compositionLegs/getCompositionLegsModel.ts';

interface CompositionLegsGridProps {
  rfqId: string;
  strategyId: string;
  compositionLeg: CompositionLeg;
}

export function CompositionLegsGrid({
  rfqId,
  strategyId,
  compositionLeg,
}: CompositionLegsGridProps) {
  const dispatch = useAppDispatch();
  const { clientWay, underlyingTypes } = useAppSelector(state =>
    getCompositionLegsGridModel(state, rfqId, strategyId, selectors),
  );

  function onLegRemoved(legId: string) {
    dispatch(neosThunks.createRfqDeleteLegThunk(legId));
  }

  function onLegUnderlyingChanged(productId: string, underlyingId: string | undefined) {
    dispatch(
      neosActionCreators.createNeosChangeCompositionLegUnderlyingAction({
        rfqId,
        strategyId,
        productId,
        underlyingId,
      }),
    );
  }

  function onWeightChanged(legId: string, weight: number | undefined) {
    dispatch(
      neosThunks.createRfqChangeLegWeightThunk(
        rfqId,
        strategyId,
        legId,
        weight ? weight / 100 : weight,
      ),
    );
  }

  function onSizeChanged(legId: string, size: number | undefined) {
    dispatch(
      neosThunks.createRfqChangeStrategyOrLegSizeThunk(rfqId, {
        strategyId,
        legId,
        value: size,
        isStrategyUpdate: false,
      }),
    );
  }

  function onSpotChanged(underlyingId: string, spot: number | undefined) {
    dispatch(
      neosActionCreators.referenceCrudActions.update({ rfqId, underlyingId }, { refSpot: spot }),
    );
  }

  function onExpectedNChanged(productId: string, expectedN: number | undefined) {
    dispatch(neosActionCreators.productCrudActions.update(productId, { expectedN }));
  }

  function onVolStrikeChanged(legId: string, quoteId: string, value: number | undefined) {
    dispatch(
      neosThunks.createRfqUpdateLegQuoteThunk({
        rfqId,
        strategyId,
        quoteId,
        legId,
        quotePatch: mapVolStrikeToQuoteByClientWay(clientWay, value),
        scope: LegRecomputeChangedScope.TRADER_PRICE,
      }),
    );
  }

  return (
    <>
      <button
        className="btn btn-icon btn-flat-primary"
        onClick={() => onLegRemoved(compositionLeg.legId)}
      >
        <i className="icon icon-md">delete_forever</i>
      </button>
      <ErrorHighlight
        key={compositionLeg.legId}
        errorField={'underlying'}
        related={{ rfqId, legId: compositionLeg.legId, strategyId }}
      >
        <UnderlyingInput
          componentId={`neos-composition-underlying-${compositionLeg.legId}`}
          underlyingId={compositionLeg.underlyingId}
          bloombergCode={compositionLeg.bloombergCode}
          underlyingTypes={underlyingTypes}
          positionFixed={true}
          data-e2e="composition-legs-underlying"
          onChange={(val: TypeaheadValue | undefined) =>
            onLegUnderlyingChanged(compositionLeg.productId, val?.value)
          }
          className="menu-in-modal"
        />
      </ErrorHighlight>
      <NumericInput
        unit="%"
        value={compositionLeg.weight}
        onBlur={value => onWeightChanged(compositionLeg.productId, value)}
        maximumFractionDigits={2}
        onlyPositiveNumbers
      />
      <NumericInput
        value={compositionLeg.size}
        unit={compositionLeg.sizeUnit}
        onBlur={value => onSizeChanged(compositionLeg.legId, value)}
        maximumFractionDigits={2}
      />
      <NumericInput
        value={compositionLeg.spot}
        readOnly={compositionLeg.spotDisabled}
        onBlur={value => onSpotChanged(compositionLeg.underlyingId!, value)}
      />
      <NumericInput
        value={compositionLeg.expectedN}
        onBlur={value => onExpectedNChanged(compositionLeg.productId, value)}
      />
      <NumericInput
        value={compositionLeg.volStrike}
        onBlur={value =>
          onVolStrikeChanged(compositionLeg.legId, compositionLeg.quoteId ?? '', value)
        }
      />
    </>
  );
}
